/* eslint-disable no-unused-vars */
import axios from 'axios'
// eslint-disable-next-line import/no-unresolved

// const user = JSON.parse(localStorage.getItem('user'))
const API_URL = 'https://imgapi.mytsalud.com/api/' // produccion
// const API_URL = 'http://localhost/api/' // docker
// const API_URL = 'http://127.0.0.1:8000/api/' // local

export default {
  namespaced: true,
  worksheets: {},
  state: {
    dataRetor: '',
    upload_documento: 0,
  },
  actions: {
    listarProfesionalSalud() {
      return axios.get(`${API_URL}listar_profesionales/new`)
    },
    // historial
    descargarDatosPacientes() {
      return axios.get(`${API_URL}mostrar_data_paciente/new`)
    },
    // medico
    validarUsuario({ commit }, correo) {
      return axios.post(`${API_URL}validar_usuario/new`, correo)
        .then(response => response)
    },

    capturarFecha({ commit }, fechas) {
      return axios.post(`${API_URL}validarFechaReporte/new`, fechas)
        .then(response => response)
    },
    validarPaciente({ commit }, documento) {
      return axios.post(`${API_URL}validar_paciente/new`, documento)
        .then(response => response)
    },
    validarCup({ commit }, cup) {
      return axios.post(`${API_URL}consultar_cups/new`, cup)
        .then(response => response)
    },
    guardarPaciente({ commit }, dataPaciente) {
      return axios.post(`${API_URL}guardar_informacion_paciente/new`, dataPaciente)
        .then(response => response)
    },
    tablaRegistro({ commit }) {
      return axios.get(`${API_URL}tabla-registro/new`)
        .then(response => response)
    },
    dataProfesionalCargar({ commit }, dataFecha) {
      return axios.post(`${API_URL}data_profesinal_cargar/new`, dataFecha)
        .then(response => response)
    },
    crearPaciente({ commit }, dataPaciente) {
      return axios.post(`${API_URL}crear_paciente/new`, dataPaciente)
        .then(response => response)
    },
    actualizarPaciente({ commit }, dataResultado) {
      return axios.post(`${API_URL}actualizar_paciente/new`, dataResultado)
        .then(response => response)
    },
    editarPaciente({ commit }, dataEitarPaciente) {
      return axios.post(`${API_URL}consultar_paciente_editar/new`, dataEitarPaciente)
        .then(response => response)
    },
    guardarPacienteEditado({ commit }, dataGuardarPaciente) {
      return axios.post(`${API_URL}guardar_paciente_editado/new`, dataGuardarPaciente)
        .then(response => response)
    },
    enviarMensaje({ commit }, dataTelefono) {
      return axios.post(`${API_URL}enviar_mensaje/new`, dataTelefono)
        .then(response => response)
    },
    eliminarPaciente({ commit }, dataCodigo) {
      return axios.post(`${API_URL}eliminar_paciente/new`, dataCodigo)
        .then(response => response)
    },
    crearProfesional({ commit }, dataProfesional) {
      return axios.post(`${API_URL}crear_profesional/new`, dataProfesional)
        .then(response => response)
    },
  },
  mutations: {
    // eslint-disable-next-line camelcase
    SET_UPLOAD_DOCUMENTO(state, upload_documento) {
      // eslint-disable-next-line camelcase
      state.upload_documento = upload_documento
    },
    REINICIAR_UPLOAD_DOCUMENTO(state) {
      state.upload_documento = 0
    },
  },
}
