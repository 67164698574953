import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      meta: {
        layout: 'full',
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/medico-myt',
      name: 'medico',
      component: () => import('@/views/Medico.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/paciente',
      name: 'Paciente',
      component: () => import('@/views/Paciente.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/linea-de-frente',
      name: 'linea-de-frente',
      component: () => import('@/views/LineaFrente.vue'),
      meta: {
        pageTitle: 'linea de frente',
        breadcrumb: [
          {
            text: 'linea de frente',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profesional',
      name: 'profesional',
      component: () => import('@/views/ProfesionalCargar.vue'),
      meta: {
        pageTitle: 'profesional',
        breadcrumb: [
          {
            text: 'profesional',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/registro',
      name: 'registro',
      component: () => import('@/views/Registro.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/historial',
      name: 'historial',
      component: () => import('@/views/historial.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/validar-ingreso',
      name: 'validar-ingreso',
      component: () => import('@/views/historial.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/crear-profesional',
      name: 'crear-profesional',
      component: () => import('@/views/CrearProfesionalSalud.vue'),
      meta: {
        pageTitle: 'Crear Profesional De La Salud',
        breadcrumb: [
          {
            text: 'Crear Profesional',
            active: true,
          },
        ],
      },
    },
    {
      path: '/prueba',
      name: 'prueba',
      // eslint-disable-next-line import/no-unresolved
      component: () => import('@/views/prueba.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
