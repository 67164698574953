import axios from 'axios'

// const API_URL = 'http://service-management.devel/api/auth/'
const API_URL = 'https://api.mytsalud.com/api/auth/'
class AuthService {
  // eslint-disable-next-line class-methods-use-this
  login(user) {
    return axios
      .post(`${API_URL}login`, {
        email: user.email,
        password: user.password,
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data.user))
        }
        return response.data.user
      })
  }

  // eslint-disable-next-line class-methods-use-this
  logout() {
    localStorage.removeItem('user')
  }

  // eslint-disable-next-line class-methods-use-this
  register(user) {
    return axios.post(`${API_URL}registro`, {
      nombre: user.nombre,
      email: user.email,
      password: user.password,
    }).then(response => {
      if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data.user))
      }
      return response.data.user
    })
  }
}
export default new AuthService()
